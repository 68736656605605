$(document).ready(function() {
  var darkModeToggle = $('#darkModeToggle');
  var iconSun = $('.icon-sun');
  var iconMoon = $('.icon-moon');

  function toggleDarkMode() {
    var darkModeEnabled = darkModeToggle.prop('checked');
    $('body').toggleClass('dark-mode', darkModeEnabled);
    localStorage.setItem('darkModeEnabled', darkModeEnabled);

    // Toggle visibility of sun and moon icons based on switch state
    if (darkModeEnabled) {
      iconSun.hide();
      iconMoon.show();
      // Change sidebar class to dark mode
      $('aside').removeClass('navbar-light').addClass('navbar-dark');
      // Change navbar class to dark mode
      $('nav').removeClass('navbar-light').addClass('navbar-dark');
    } else {
      iconSun.show();
      iconMoon.hide();
      // Revert sidebar class to light mode
      $('aside').removeClass('navbar-dark').addClass('navbar-light');
      // Revert navbar class to light mode
      $('nav').removeClass('navbar-dark').addClass('navbar-light');
    }
  }

  if (!$('body').hasClass('login-page')) {
    if (darkModeToggle) {
      darkModeToggle.on('change', toggleDarkMode);

      // Check dark mode state in localStorage when page loads
      var darkModeEnabled = localStorage.getItem('darkModeEnabled') === 'true';
      darkModeToggle.prop('checked', darkModeEnabled);
      toggleDarkMode(); // Calls the function to toggle icons based on the initial state of the switch
    }
  }
});