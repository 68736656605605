
require("@nathanvda/cocoon")

$(function () {

  //Date picker
  $('#panel_locker_franchise_agreement_due_date').datepicker({
    autoclose: true,
    language: 'pt-BR',
    format: 'dd/mm/yyyy',
  });

  //Date picker
  $('#panel_locker_property_lease_agreement_due_date').datepicker({
    autoclose: true,
    language: 'pt-BR',
    format: 'dd/mm/yyyy',
  });

  //Date picker
  $('#panel_locker_insurance_policy_due_date').datepicker({
    autoclose: true,
    language: 'pt-BR',
    format: 'dd/mm/yyyy',
  });

  $("#lockers-table").DataTable({
    "dom": '<"d-flex flex-row"l><"ml-auto"f>rt<"d-flex flex-row"i><"ml-auto"p>',
    "columnDefs": [{
      "targets": [3, 4],
      "orderable": false
    }],
    language: {
      url:
        'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json'
    },
  });
});
