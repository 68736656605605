// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require cocoon

import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "@fortawesome/fontawesome-free/js/all";
require("jquery");
require("datatables.net-bs4");
require("@nathanvda/cocoon");
import "select2/dist/js/select2.full.min";
require('admin-lte');
import "./dark_mode";
require("./admins")
require("./lockers")


$(() => {
  $('.select2').select2();
  $('[data-toggle="tooltip"]').tooltip();
});

ActiveStorage.start()

